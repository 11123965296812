function PageWrapper({ children }) {
    return (
        <>
            <h1>Hello</h1>
            {children}
        </>
    );
}

export default PageWrapper;
